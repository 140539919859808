import IdobaLogo from '../components/icons/IdobaLogin';
import { MAINTENANCE_CONFIG } from 'utils/maintenance';
import { Container, Box, Alert } from '@mui/material';

const Maintenance = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Alert sx={{ mt: 5, width: '620px' }} severity="error">
        Our website will undergo scheduled maintenance from{' '}
        {MAINTENANCE_CONFIG.plannedMaintenanceStartDate} to{' '}
        {MAINTENANCE_CONFIG.plannedMaintenanceEndDate}. During this period, you may experience
        temporary disruptions
        <br /> We apologise for any inconvenience and appreciate your understanding.
      </Alert>
      <Container sx={{ mt: 13 }} maxWidth="xs">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: 126,
            borderRadius: '4px',
            mb: '32px',
          }}
        >
          <IdobaLogo />
        </Box>
      </Container>
    </Box>
  );
};

export default Maintenance;
